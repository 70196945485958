import { PropertyConverter } from './property-converter.interface';
import { DocumentErrors } from '@parashift/shared/constants';

interface EmptyObject {
  [attribute: string]: any;
}

export class DocumentErrorConverter implements PropertyConverter {
  deserialize(errors: string[] = []): object {
    if (!Array.isArray(errors)) {
      return errors;
    }

    const errorObject: EmptyObject = {};

    for (const key in DocumentErrors) {
      if (Object.prototype.hasOwnProperty.call(DocumentErrors, key)) {
        errorObject[key] = false;
      }
    }

    errors.forEach(error => {
      errorObject[error] = true;
    });

    return errorObject;
  }

  serialize(errors: EmptyObject): string[] {
    const errorsArray = [];

    for (const key in errors) {
      if (Object.prototype.hasOwnProperty.call(errors, key) && errors[key] === true) {
        errorsArray.push(key);
      }
    }

    return errorsArray;
  }
}
