import { Model, Attribute, BelongsTo, HasMany } from './decorators';
import { BaseApiModel } from './base';
import { Batch } from './batch.model';
import { Coordinates } from '../interfaces/coordinates.interface';
import { WorkflowStep, WorkflowStatus } from './log.model';
import { ActiveStorageAttachmentType, CommentType, ConfigurationType, DocumentFieldType, DocumentTaskType, DocumentType, DocumentTypeType } from '@parashift/shared/types';
import { DocumentErrorConverter } from './converters/document-error.converter';
import { ActiveStorageAttachment } from './active-storage-attachment.model';
import { Configuration } from './configuration.model';
import { DocumentField } from './document-field.model';
import { DocumentTask } from './document-task.model';
import { DocumentType as DocumentTypeModel } from './document-type.model';
import { Comment } from './comment.model';
import { InboundChannelInfo } from '../interfaces/interfaces.index';

export interface ClassificationCandidate {
  confidence: number;
  prediction: string;
}

const DocumentStatus = {
  pending: $localize `:@@common.pending:Pending` as 'pending',
  in_progress: $localize `:@@common.in_progress:In progress` as 'in_progress',
  done: $localize `:@@common.done:Done` as 'done',
  failed: $localize `:@@common.failed:Failed` as 'failed',
};
type DocumentStatus = (typeof DocumentStatus)[keyof typeof DocumentStatus];
export { DocumentStatus };

const InboundChannel = {
  api: $localize `:@@common.api:API` as 'api',
  app: $localize `:@@common.app:App` as 'app',
  email: $localize `:@@common.email:Email` as 'email',
  integration: $localize `:@@common.integration:Integration` as 'integration',
};
type InboundChannel = (typeof InboundChannel)[keyof typeof InboundChannel];
export { InboundChannel };

export interface DocumentLayout {
  'ocr-engine': string;
  tokens: Token[];
  version: string;
}

export interface Token {
  coordinates: Coordinates;
  data_type: string;
  index: number;
  label: string;
  ocr_confidence: number;
  page_aspect_ratio: number;
  page_number: number;
  text: string;
  type?: 'token';
}

export interface DocumentTokenClusters {
  candidates: ClusterCandidate[];
}

export interface ClusterCandidate {
  confidence: number;
  coordinates: Coordinates;
  index: number;
  ocr_confidence: number;
  page_number: number;
  prediction_confidence: number;
  prediction: any;
  text: string;
  type: 'cluster';
}

export interface CustomFields {
  [attribute: string]: any;
}

export interface PreviewItem {
  url: string;
  file_name: string;
  mime_type: string;
}

export interface ParentContainerFile {
  id: number;
  link: string;
}

export interface DocumentLevels {
  currentLevel: Levels;
  nextLevel?: Levels;
  availableLevels: Levels[];
}

export enum Levels {
  'first-level' = 'first-level',
  'second-level' = 'second-level',
  'third-level' = 'third-level'
}

export type SeparationViewPage = [number, number];
export type SeparationViewDoc = SeparationViewPage[];

export interface SeparationConfiguration {
  documents: number[][];
  rotation: [number, number][];
  deletion?: number[][]; // no deletion array meaning automatic separation
  view?: SeparationViewDoc[];
  separation_confidence?: [number, number][];
}

export type DocumentOrBatch = Document | Batch;

export type TaskToLevelType = 'extraction-first-level' | 'extraction-second-level' | 'extraction-third-level';

export interface DocumentGroup {
  groupName: string;
  records: DocumentOrBatch[];
  collapsed: boolean;
}

export enum ValidationLevel {
  'first-level',
  'second-level',
  'third-level',
  'qc'
}

export enum ValidationStep {
  'classification',
  'extraction',
  'qc'
}

export interface DocumentPlainModel {
  id: string;
  assigned_user_id: number;
  classification_candidates: ClassificationCandidate[];
  classification_first_level_tenant_id: number;
  classification_second_level_tenant_id: number;
  classification_third_level_tenant_id: number;
  classification_qc_tenant_id: number;
  classification_scope: string[];
  configuration_id: number;
  created_at: string;
  current_document_task_id: number;
  custom_fields: CustomFields;
  document_language: string;
  document_language_confidence: number;
  document_layout: DocumentLayout;
  document_token_clusters: DocumentTokenClusters;
  document_type_id: number;
  editable: boolean;
  editing_tenant_id: number;
  error_codes: any;
  exported_at: string;
  external_id: string;
  extraction_first_level_tenant_id: number;
  extraction_qc_tenant_id: number;
  extraction_second_level_tenant_id: number;
  extraction_third_level_tenant_id: number;
  has_custom_source_file_types: boolean;
  inbound_channel: InboundChannel;
  inbound_channel_details: string;
  lock_timeout: string;
  lock_user_id: string;
  lock_version: string;
  name: string;
  not_for_training: boolean;
  pages_count: number;
  parent_batch_id: string;
  parent_container_files: ParentContainerFile[];
  qc_external_tenant_id: number;
  qc_internal_tenant_id: number;
  recognition_confidence: number;
  reset_lock: boolean;
  separation_configuration: SeparationConfiguration;
  separation_first_level_tenant_id: number;
  separation_qc_tenant_id: number;
  separation_second_level_tenant_id: number;
  separation_third_level_tenant_id: number;
  separation_validation_required: boolean;
  sla_deadline: number;
  status: DocumentStatus;
  tags: string[];
  tenant_id: number;
  track_changes: boolean;
  updated_at: string;
  validation_profile: string;
  validation_required: boolean;
  workflow_status: WorkflowStatus;
  workflow_step: WorkflowStep;

  comments: (Comment['plainModel'] | Partial<Comment['plainModel']>)[];
  configuration: (Configuration['plainModel'] | Partial<Configuration['plainModel']>)[];
  container_files: (ActiveStorageAttachment['plainModel'] | Partial<ActiveStorageAttachment['plainModel']>)[];
  document_fields: (DocumentField['plainModel'] | Partial<DocumentField['plainModel']>)[];
  document_tasks: (DocumentTask['plainModel'] | Partial<DocumentTask['plainModel']>)[];
  document_type: DocumentTypeModel['plainModel'] | Partial<DocumentTypeModel['plainModel']>;
  input_files: (ActiveStorageAttachment['plainModel'] | Partial<ActiveStorageAttachment['plainModel']>)[];
  output_file: (ActiveStorageAttachment['plainModel'] | Partial<ActiveStorageAttachment['plainModel']>)[];
  previews: (ActiveStorageAttachment['plainModel'] | Partial<ActiveStorageAttachment['plainModel']>)[];
  thumbnail_attachment: ActiveStorageAttachment['plainModel'] | Partial<ActiveStorageAttachment['plainModel']>;
}

@Model({ type: DocumentType })
export class Document extends BaseApiModel<DocumentPlainModel> {

  protected currentTask: DocumentTask | Partial<DocumentTask>;

  @Attribute()
  assigned_user_id: number;

  @Attribute()
  classification_candidates: ClassificationCandidate[];

  @Attribute()
  classification_first_level_tenant_id: number;

  @Attribute()
  classification_qc_tenant_id: number;

  @Attribute()
  classification_scope: string[];

  @Attribute()
  classification_second_level_tenant_id: number;

  @Attribute()
  classification_third_level_tenant_id: number;

  @Attribute({ readonly: true })
  configuration_id: number

  @Attribute()
  created_at: string;

  @Attribute()
  current_document_task_id: number;

  @Attribute()
  custom_fields: CustomFields;

  @Attribute({ readonly: true })
  document_language: string;

  @Attribute({ readonly: true })
  document_language_confidence: number;

  @Attribute({ readonly: true })
  document_layout: DocumentLayout;

  @Attribute({ readonly: true })
  document_token_clusters: DocumentTokenClusters;

  @Attribute()
  document_type_id: number;

  @Attribute()
  editable: boolean;

  @Attribute()
  editing_tenant_id: number;

  @Attribute({ converter: new DocumentErrorConverter() })
  error_codes: any;

  @Attribute()
  exported_at: string;

  @Attribute()
  external_id: string;

  @Attribute()
  extraction_first_level_tenant_id: number;

  @Attribute()
  extraction_qc_tenant_id: number;

  @Attribute()
  extraction_second_level_tenant_id: number;

  @Attribute()
  extraction_third_level_tenant_id: number;

  @Attribute({ readonly: true })
  has_custom_source_file_types: boolean;

  @Attribute({ readonly: true })
  inbound_channel: InboundChannel;

  @Attribute({ readonly: true })
  inbound_channel_details: string;

  @Attribute({ readonly: true })
  lock_timeout: string;

  @Attribute({ readonly: true })
  lock_user_id: string;

  @Attribute()
  lock_version: string;

  @Attribute()
  name: string;

  @Attribute()
  not_for_training: boolean;

  @Attribute({ readonly: true })
  pages_count: number;

  @Attribute({ readonly: true })
  parent_batch_id: string;

  @Attribute({ readonly: true })
  parent_container_files: ParentContainerFile[];

  @Attribute()
  qc_external_tenant_id: number;

  @Attribute()
  qc_internal_tenant_id: number;

  @Attribute({ readonly: true })
  recognition_confidence: number;

  @Attribute()
  reset_lock: boolean;

  @Attribute()
  separation_configuration: SeparationConfiguration;

  @Attribute()
  separation_first_level_tenant_id: number;

  @Attribute()
  separation_qc_tenant_id: number;

  @Attribute()
  separation_second_level_tenant_id: number;

  @Attribute()
  separation_third_level_tenant_id: number;

  @Attribute()
  separation_validation_required: boolean;

  @Attribute()
  sla_deadline: number;

  @Attribute()
  status: DocumentStatus;

  @Attribute()
  tags: string[];

  @Attribute()
  tenant_id: number;

  @Attribute()
  track_changes: boolean;

  @Attribute()
  updated_at: string;

  @Attribute()
  validation_profile: string;

  @Attribute()
  validation_required: boolean;

  @Attribute()
  workflow_status: WorkflowStatus;

  @Attribute()
  workflow_step: WorkflowStep;

  // FE only

  shortened_name: string;

  // Includes / Relations

  @HasMany({ class: CommentType, sidepostable: true })
  comments: (Comment | Partial<Comment>)[];

  @BelongsTo({ class: ConfigurationType, sidepostable: false, readonly: true })
  configuration: Configuration;

  @HasMany({ class: ActiveStorageAttachmentType, sidepostable: false })
  container_files: (ActiveStorageAttachment | Partial<ActiveStorageAttachment>)[];

  @HasMany({ class: DocumentFieldType, sidepostable: true })
  document_fields: (DocumentField | Partial<DocumentField>)[];

  @HasMany({ class: DocumentTaskType, sidepostable: true })
  document_tasks: (DocumentTask | Partial<DocumentTask>)[];

  @BelongsTo({ class: DocumentTypeType, sidepostable: true })
  document_type: DocumentTypeModel;

  @HasMany({ class: ActiveStorageAttachmentType, sidepostable: true })
  input_files: (ActiveStorageAttachment | Partial<ActiveStorageAttachment>)[];

  @BelongsTo({ class: ActiveStorageAttachmentType, readonly: true })
  output_file: (ActiveStorageAttachment | Partial<ActiveStorageAttachment>);

  @HasMany({ class: ActiveStorageAttachmentType, readonly: true })
  previews: (ActiveStorageAttachment | Partial<ActiveStorageAttachment>)[];

  @BelongsTo({ class: ActiveStorageAttachmentType, readonly: true })
  thumbnail_attachment: (ActiveStorageAttachment | Partial<ActiveStorageAttachment>);

  // Getters

  get totalPagesCount(): number {
    return this.pages_count ?? this.previews.length;
  }

  get sortedTasks(): (DocumentTask | Partial<DocumentTask>)[] {
    let tasks: (DocumentTask | Partial<DocumentTask>)[] = [];

    if (this.document_tasks && Array.isArray(this.document_tasks)) {
      tasks = this.document_tasks.sort((a, b) => Number(b.id) - Number(a.id));
    }

    return tasks;
  }

  get inboundChannelInfo(): InboundChannelInfo {
    return {
      channel: this.inbound_channel,
      details: this.inbound_channel_details ? this.inbound_channel_details.split('/') : [],
      configuration: { id: this.configuration_id, name: this.validation_profile }
    };
  }

  // // Methods

  getPreviews(): ActiveStorageAttachment[] | Partial<ActiveStorageAttachment>[] {
    if (this.previews && this.previews.length > 0) {
      return this.previews;
    } else {
      return [{ url: '/assets/images/common/missing_preview.png' }];
    }
  }

  getPreviewUrls(): string[] {
    if (this.previews && this.previews.length > 0) {
      return this.previews.map(x => x.url);
    } else {
      return ['/assets/images/common/missing_preview.png'];
    }
  }

  getPreview(index?: number): string {
    if (this.previews && this.previews.length > 0) {
      return this.previews[index || 0].url;
    } else {
      return '/assets/images/common/missing_preview.png';
    }
  }

  getThumbnail(): string {
    if (this.thumbnail_attachment && this.thumbnail_attachment.url) {
      return this.thumbnail_attachment.url;
    } else if (this.previews && this.previews.length > 0 && this.previews[0].url) {
      return this.previews[0].url;
    } else {
      return '/assets/images/common/missing_preview.png';
    }
  }

  getCurrentTask(tenant_id?: number, qc?: 'external' | 'internal' | undefined): DocumentTask | Partial<DocumentTask> {
    if (this.workflow_step === 'qc') {
      const pendingTasks = this.document_tasks.filter(x => x.status === 'pending');
      const internalQcTask = pendingTasks.find(x => x.workflow_task === 'internal-qc');
      if (internalQcTask && (qc === 'internal' || qc === undefined) && this.qc_internal_tenant_id === tenant_id) {
        return this.currentTask = internalQcTask;
      }
      const externalQcTask = pendingTasks.find(x => x.workflow_task === 'external-qc');
      if (externalQcTask && (qc === 'external' || qc === undefined) && this.qc_external_tenant_id === tenant_id) {
        return this.currentTask = externalQcTask;
      }
      return undefined;
    }
    if (!this.currentTask && this.document_tasks && Array.isArray(this.document_tasks)) {
      this.currentTask = this.document_tasks.reverse().find(task => task.status === 'pending');
    }

    return this.currentTask;
  }

  isInQcStep(step: 'classification' | 'extraction' | 'separation'): boolean {
    const currentTask = this.getCurrentTask();
    if (currentTask && currentTask.workflow_task === step + '-qc') {
      return true;
    }

    return false;
  }

  getValidationLevelsWithCurrentLevel(step: 'classification' | 'extraction' | 'separation'): DocumentLevels {
    const currentTask = this.getCurrentTask();
    const documentLevels: DocumentLevels = {
      currentLevel: Levels['first-level'],
      availableLevels: this.getAvailableValidationLevels(step)
    };

    if (currentTask && currentTask.workflow_step === step + '_validation') {
       const splitLevel = currentTask.workflow_task.split(step + '-');

       if (splitLevel[1]) {
        documentLevels.currentLevel = splitLevel[1] as Levels;
      }
    }

    if (documentLevels.availableLevels.length > 1) {
      documentLevels.nextLevel = this.getNextLevel(documentLevels.availableLevels, documentLevels.currentLevel);
    }

    return documentLevels;
  }

  getAvailableExtractionLevels(): DocumentLevels {
    const documentLevels: DocumentLevels = {
      currentLevel: undefined,
      availableLevels: []
    };

    const currentTask = this.getCurrentTask();

    if (
      !currentTask
      || (currentTask.workflow_step !== 'extraction_validation' && currentTask.workflow_step !== 'extraction_qc' )
    ) {
      return documentLevels;
    }

    const taskToLevel = {
      'extraction-first-level': 'first-level',
      'extraction-second-level': 'second-level',
      'extraction-third-level': 'third-level',
    };

    const levels = ['extraction-first-level', 'extraction-second-level', 'extraction-third-level'];
    const extractionTasks = this.document_tasks.filter((task) => task.workflow_step === 'extraction_validation');

    // const availableLevels: { [attr: string]: string }[] = [];
    const availableLevels: Levels[] = [];
    levels.forEach(level => extractionTasks.find(task => task.workflow_task === level ? availableLevels.push(taskToLevel[task.workflow_task as TaskToLevelType] as Levels) : undefined));

    documentLevels.currentLevel = taskToLevel[currentTask.workflow_task as TaskToLevelType] as Levels;
    documentLevels.availableLevels = availableLevels;

    if (documentLevels.availableLevels.length > 1) {
      documentLevels.nextLevel = this.getNextLevel(documentLevels.availableLevels, documentLevels.currentLevel);
    }

    return documentLevels;
  }

  getNextLevel(availableLevels: Levels[], currentLevel: Levels): Levels {
    let nextLevel: Levels;
    const index = availableLevels.indexOf(currentLevel);

    if (index >= 0 && index < availableLevels.length - 1) {
      nextLevel = availableLevels[index + 1];
    } else {
      nextLevel = availableLevels[0];
    }

    return nextLevel;
  }

  getAvailableValidationLevels(step: 'classification' | 'extraction' | 'separation'): Levels[] {
    const availableLevels: Levels[] = [];
    const levels = ['first', 'second', 'third'];

    levels.forEach(level => {
      if (this[step + '_' + level + '_level_tenant_id']) {
        availableLevels.push(level + '-level' as Levels);
      }
    });

    return availableLevels;
  }

  isValidationAllowed(step: 'classification' | 'extraction' | 'separation', currentTenantId?: number): boolean {
    const currentTask = this.getCurrentTask();

    // Admins (MDT)
    if (
      !currentTenantId
      && currentTask
      && (
        currentTask.workflow_step === step + '_validation'
        || currentTask.workflow_step === step + '_qc'
      )
    ) {
      return true;
    }

    // Clients only if they are the validation tenant (PDC)
    if (
      currentTask
      && (
        currentTask.workflow_step === step + '_validation'
        || currentTask.workflow_step === step + '_qc'
      )
    ) {
      const level = currentTask.workflow_task.replace(/-/g, '_');

      if (currentTenantId === this[level + '_tenant_id']) {
        return true;
      }
    }

    return false;
  }

  isBulkClassificationAllowed(currentTenantId: number): boolean {
    const currentTask = this.getCurrentTask();

    if (
      currentTask
      && (
        currentTask.workflow_step === 'classification_validation'
        || currentTask.workflow_step === 'classification_qc'
      )
    ) {
      const level = currentTask.workflow_task.replace(/-/g, '_');
      return currentTenantId === this[level + '_tenant_id'];
    }

    return true;
  }

  getDocumentTaskByStepAndTask(workflowStep: WorkflowStep, workflowTask: string): DocumentTask | Partial<DocumentTask> {
    return this.document_tasks.find(dc => dc.workflow_step === workflowStep && dc.workflow_task === workflowTask);
  }

  getCleanedUpClassificationScope(tenantDocumentTypes: DocumentTypeModel[] = []): string[] {
    const currentScope = this.classification_scope || [];
    const documentTypes = tenantDocumentTypes.map(dt => dt.identifier);
    return currentScope.filter(scope => documentTypes.includes(scope));
  }
}
